import { render, staticRenderFns } from "./page.vue?vue&type=template&id=1060ac71&lang=pug&"
import script from "./page.vue?vue&type=script&lang=coffee&"
export * from "./page.vue?vue&type=script&lang=coffee&"
import style0 from "./page.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import ActionDock from '@/components/common/action_dock.vue'
import AttachmentList from '@/components/thread/attachment_list.vue'
import DocumentList from '@/components/document/list.vue'
import FormattedText from '@/components/common/formatted_text.vue'
import GroupOnboardingCard from '@/components/group/onboarding_card.vue'
import JoinGroupButton from '@/components/group/join_button.vue'
import LinkPreviews from '@/components/thread/link_previews.vue'
import Loading from '@/components/common/loading.vue'
import Space from '@/components/common/space.vue'
import TrialBanner from '@/components/group/trial_banner.vue'
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {ActionDock,AttachmentList,DocumentList,FormattedText,GroupOnboardingCard,JoinGroupButton,LinkPreviews,Loading,Space,TrialBanner,VContainer,VDivider,VImg,VMain,VTab,VTabs,VTabsSlider})
